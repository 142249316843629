import {Link} from "react-router-dom";
import InternalUrls from "../../utils/internalUrls";

const Home = () => {
    return (
        <div>
        <h1>Domowe narzędzia</h1>
            <Link to={InternalUrls.shortTimer}>Short Timer</Link>
        </div>
    )
}

export default Home;