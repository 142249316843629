import "./button.css";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Button = ({value, disable = false, iconName, onClick, negative = false}: {
    value: string,
    disable?: boolean,
    iconName?: IconProp,
    onClick?: () => void,
    negative?: boolean
}) => {
    return (
        <>
            <button onClick={onClick}
                    className={`button ${disable && 'disable'} ${negative && 'negative'}`}>{iconName &&
                <FontAwesomeIcon icon={iconName}/>} <span>{value}</span></button>
        </>
    );
}

export default Button;