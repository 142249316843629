import "./shortTimer.css"

const ProgressCalendar = () => {

    const getDaysInMonth = (month: number, year: number) => new Date(year, month + 1, 0).getDate()

    const getAllDaysOfCurrentMonth = () => {
        const now = new Date();
        const month = now.getMonth();
        const year = now.getFullYear();
        const daysInMonth = getDaysInMonth(month, year);

        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const isDayEnded = (day: Date): boolean => {
        const now = new Date();
        return day.getTime() < now.getTime();
    }

    const isDayCompleted = (day: Date): boolean => {
        return false;
    }

    return (
        <div className="progress-calendar--wrapper">
            {getAllDaysOfCurrentMonth().map((day) => (
                <div
                    className={`${isDayEnded(day) && (isDayCompleted(day) ? 'progress-calendar--completed' : 'progress-calendar--incomplete')}`}
                    key={day.getDate()}>{day.getDate()}</div>
            ))}
        </div>
    );
}

export default ProgressCalendar;