import {useEffect, useRef, useState} from "react";
import {areDatesEqual} from "../../utils/comparator";
import ProgressCalendar from "./ProgressCalendar";
import Button from "../../components/Button/Button";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";


interface TimerUnit {
    date: Date,
    hours: number,
    minutes: number,
    seconds: number
}

const ShortTimer = () => {
    const [currentTimer, setCurrentTimer] = useState<TimerUnit>({
        date: new Date(),
        hours: 1,
        minutes: 0,
        seconds: 0
    });
    const [isTimer, setIsTimer] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const timerId = useRef<NodeJS.Timer | null>(null);


    const decrementTimer = () => {
        setCurrentTimer((prevTimer) => {
            if (prevTimer.seconds > 0) {
                return {...prevTimer, seconds: prevTimer.seconds - 1};
            } else if (prevTimer.minutes > 0) {
                return {...prevTimer, minutes: prevTimer.minutes - 1, seconds: 59};
            } else if (prevTimer.hours > 0) {
                return {...prevTimer, hours: prevTimer.hours - 1, minutes: 59, seconds: 59};
            } else {
                // Timer finished
                if (prevTimer.seconds > -59) {
                    return {...prevTimer, seconds: prevTimer.seconds - 1}
                } else if (prevTimer.minutes > -59) {
                    return {...prevTimer, minutes: prevTimer.minutes - 1, seconds: 0};
                } else {
                    return {...prevTimer, hours: prevTimer.hours - 1, minutes: 0, seconds: 0};
                }
            }
        });
    };

    useEffect(() => {
        const storedTimer = localStorage.getItem("timer")
        if (storedTimer) {
            const parsedStoredTimer = JSON.parse(storedTimer);
            const timerDate = new Date(parsedStoredTimer.date);
            if (areDatesEqual(timerDate, new Date())) {
                setCurrentTimer({
                    date: timerDate,
                    hours: parsedStoredTimer.hours,
                    minutes: parsedStoredTimer.minutes,
                    seconds: parsedStoredTimer.seconds
                })
            }
        }
        setIsLoading(false);
    }, [setCurrentTimer]);

    useEffect(() => {
        if (isTimer && currentTimer) {
            timerId.current = setInterval(decrementTimer, 1000);
            return () => {
                if (timerId.current) clearInterval(timerId.current);
            }
        }
    }, [isTimer, currentTimer]);

    useEffect(() => {
        if (!isLoading) {
            localStorage.setItem("timer", JSON.stringify(currentTimer));
            document.title = `
        ${displayTime(currentTimer.hours)}:${displayTime(currentTimer.minutes)}:${displayTime(currentTimer.seconds)}`;
        }
    }, [currentTimer, isLoading]);

    const handleStart = () => {
        setIsTimer(true);
    }

    const handlePause = () => {
        setIsTimer(false)
    }

    const displayTime = (value: number) => {
        return value < 10 && value >= 0 ? `0${value}` : value
    }

    return (
        <div className="short-time--wrapper">
            <h2>{currentTimer.date.toLocaleDateString()}</h2>
            <ProgressCalendar/>
            <div className="timer">
                <div>
                    <div>Godziny</div>
                    <div className="digit hours">{displayTime(currentTimer.hours)}</div>
                </div>
                <div>
                    <div>Minuty</div>
                    <div className="digit minutes">{displayTime(currentTimer.minutes)}</div>
                </div>
                <div>
                    <div>Sekundy</div>
                    <div className="digit seconds">{displayTime(currentTimer.seconds)}</div>
                </div>
            </div>
            <div>
                {isTimer ? <Button value="Zatrzymaj" onClick={handlePause}/> :
                    <Button value="Start" onClick={handleStart} iconName={faPlay}/>}
            </div>
        </div>
    );
}

export default ShortTimer;