import React from 'react';
import {Route, Routes} from "react-router";
import ShortTimer from "../pages/short-timer/ShortTimer";
import Home from "../pages/home/Home";
import "./global.css";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/short-timer" element={<ShortTimer/>}/>
            </Routes>

        </div>
    );
}

export default App;
